<template>
  <div id="devs-page">
    <h1>This is an devs page</h1>
  </div>
</template>

<script>
export default {
  data() {
    return {
      //comentariu ca sa nu dea ESLint warning
    };
  },
};
</script>

<style lang="scss" scoped>
//comentariu ca sa nu dea ESLint warning
</style>
